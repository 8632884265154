import React from 'react'
import { useTransition, useTranslation } from 'react-i18next'


const Cloud = () => {

    const [t, i18n] = useTranslation("global")
    
    return(
      <section className='container-cloud'>
    <div  className="  justify-center py-11 px-11">
    <p className='text-white text-4xl sm:text-5xl font-bold text-center'> {t("Cloud.titulo")}</p>
    <p className='text-gray-500 text-2xl sm:text-3xl text-center pt-6 pb-11'>{t("Cloud.sub")}</p>
    <div className=' flex justify-center items-center pt-11 pb-11'>
  <div className="grid grid-cols-1  md:grid-cols-3 xl:grid-cols-5 gap-5">
    <div className="text-white flex flex-col items-center ">
      <span className="text-5xl font-bold border-4 rounded-full w-20 h-20 items-center justify-center flex">1</span>
      <span className="text-gray-500 pt-3">{t("Cloud.uno")}</span>
      <span className='text-center pt-4'>{t("Cloud.POC")}</span>
    </div>
    <div className="text-white flex flex-col items-center">
      <span className="text-5xl font-bold border-4 rounded-full w-20 h-20 items-center justify-center flex">2</span>
      <span className="text-gray-500 pt-3">{t("Cloud.dos")}</span>
      <span className='text-center pt-4'>{t("Cloud.detalle")}</span>
    </div>
    <div className="text-white flex flex-col items-center">
      <span className="text-5xl font-bold border-4 rounded-full w-20 h-20 items-center justify-center flex">3</span>
      <span className="text-gray-500 pt-3">{t("Cloud.tres")}</span>
      <span className='text-center pt-4'>{t("Cloud.seleccion")}</span>

    </div>
    <div className="text-white flex flex-col items-center">
      <span className="text-5xl font-bold border-4 rounded-full w-20 h-20 items-center justify-center flex">4</span>
      <span className="text-gray-500 pt-3">{t("Cloud.cuatro")}</span>
      <span className='text-center pt-4'>{t("Cloud.nube")}</span>
    </div>
    <div className="text-white flex flex-col items-center">
      <span className="text-5xl font-bold border-4 rounded-full w-20 h-20 items-center justify-center flex">5</span>
      <span className="text-gray-500 pt-3">{t("Cloud.cinco")}</span>
      <span className='text-center pt-4'>{t("Cloud.optimizar")}</span>
    </div>
  </div>
</div>
    </div>
    </section>
)}


export default Cloud