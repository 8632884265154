import React, {useEffect} from 'react'
import facu from '../assets/facu.png'
import mati from '../assets/matias.png'
import luisina from '../assets/luisi.png'
import alfre from '../assets/AlfreFinal.png'
import agus from '../assets/agustin.png'
import gabi from '../assets/gabii.png'
import pancho from '../assets/pancho.png'
import { useTransition, useTranslation } from "react-i18next"

import {MdChevronLeft, MdChevronRight } from 'react-icons/md'

const Nosotros = () => {
    const [t, i18n] = useTranslation("global")

    const sliderLeft = () => {
        var slider = document.getElementById('slider')
        slider.scrollLeft = slider.scrollLeft - 300
    }

    const sliderRight= () => {
        var slider = document.getElementById('slider')
        slider.scrollLeft = slider.scrollLeft + 300
    }


  return (
<div className='bg-black z-1 text-white text-center pt-11 ' >

        <h1 className='text-4xl sm:text-5xl   mx-auto leading-normal font-bold mb-12 pt-11'>{t("nosotros.contamos")} <p className='text-rosa'>{t("nosotros.equipo")}</p></h1>   
        

    <div name='Nosotros' className='relative flex items-center '>
        
    <MdChevronLeft className='opacity-50 cursor-pointer hover:opacity-100 hidden sm:block' onClick={sliderLeft} size={500}/>
        <div  id="slider" className=' container-nosotros flex  overflow-x-scroll scroll whitespace-nowrap scroll-smooth hide-scroll-bar '> 

      {/* <div id="slider" className='flex overflow-x-scroll scroll whitespace-nowrap scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100'> */}


        <div className='elemento-contenedor w-[300px] bg-white/10 p-14 rounded-xl mx-4'>
                <img className=' mx-auto ' src={facu} alt="crear pagina web" />
                <h4 className='uppercase text-xl pt-4 '></h4>
                <p className='text-sm leading-7 w-[200px] my-3 font-light opacity-50'>
                 Facundo <br /> {t("nosotros.full")}
                </p>

            </div>


            <div className='elemento-contenedor bg-white/10 duration-500  p-14 rounded-xl mx-4'>
                <img className=' mx-auto ' src={mati} alt="crear pagina web" />
                <h4 className='uppercase text-xl font-bold '></h4>
                <p className='text-sm leading-7 w-[200px] my-3 font-light opacity-50'>
                Matias <br /> {t("nosotros.front")}
                </p>

            </div>


            <div className='elemento-contenedor bg-white/10 duration-500 group-hover:blur-sm hover:!blur-none group-hover:scale[0.85] hover:!scale-100 p-14 rounded-xl mx-4'>
                <img className=' mx-auto ' src={agus} alt="crear pagina web" />
                <h4 className='uppercase text-xl font-bold'></h4>
                <p className='text-sm leading-7 w-[200px] my-3 font-light opacity-50'>
                Agustin <br /> {t("nosotros.front")}
                </p>

            </div>

            <div className='elemento-contenedor bg-white/10 duration-500 group-hover:blur-sm hover:!blur-none group-hover:scale[0.85] hover:!scale-100 p-14 rounded-xl mx-4'>
                <img className=' mx-auto ' src={gabi} alt="crear pagina web" />
                <h4 className='uppercase text-xl font-bold '></h4>
                <p className='text-sm leading-7 w-[200px] my-3 font-light opacity-50'>
                Gabriela <br /> {t("nosotros.back")}
                </p>

            </div>


            <div className='elemento-contenedor bg-white/10 duration-500 group-hover:blur-sm hover:!blur-none group-hover:scale[0.85] hover:!scale-100 p-14 rounded-xl mx-4'>
                <img className=' mx-auto ' src={alfre} alt="crear pagina web" />
                <h4 className='uppercase text-xl font-bold'></h4>
                <p className='text-sm leading-7 w-[200px] my-3 font-light opacity-50'>
                Alfrerdo <br /> {t("nosotros.back")}
                </p>

            </div>

            <div className='elemento-contenedor bg-white/10 duration-500 group-hover:blur-sm hover:!blur-none group-hover:scale[0.85] hover:!scale-100 p-14 rounded-xl mx-4'>
                <img className=' mx-auto ' src={pancho} alt="crear pagina web" />
                <h4 className='uppercase text-xl font-bold'></h4>
                <p className='text-sm leading-7 w-[200px] my-3 font-light opacity-50'>
                 
                  Francisco <br /> {t("nosotros.dis")}
                </p>

            </div>



            <div className='elemento-contenedor bg-white/10 duration-500 group-hover:blur-sm hover:!blur-none group-hover:scale[0.85] hover:!scale-100 p-14 rounded-xl mx-4'>
                <img className=' mx-auto ' src={luisina} alt="crear pagina web" />
                <h4 className='uppercase text-xl font-bold'></h4>
                <p className='text-sm leading-7 w-[200px] my-3 font-light opacity-50'>
                Luisiana <br />Recruter
                </p>

            </div>
            
        </div>
        
            <MdChevronRight className='opacity-50 cursor-pointer hover:opacity-100 hidden sm:block' onClick={sliderRight} size={500}/>
    </div>
    
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                    <path fill="#180d5b" fill-opacity="1" d="M0,160L80,181.3C160,203,320,245,480,245.3C640,245,800,203,960,181.3C1120,160,1280,160,1360,160L1440,160L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
                  </svg>
</div>
    
  )
}

export default Nosotros