import Navbar from "./components/Navbar";
import Header from "./components/Header"
import Nosotros from "./components/Nosotros";
import Servicios from "./components/Servicios"
import Star from "./components/Star"
import Cloud from "./components/Cloud"
import Footer from "./components/Footer";
import Slider from './components/Slider'
import Brand from './components/brand/Brand'


function App() {
  return (
    <>
    <Navbar/>
    <Header/>
    <Brand/>
    <Servicios/>

    <Cloud/>
    <Nosotros/>
    <Footer/>
    </>
  );
}

export default App;
