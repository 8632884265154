import React from "react";
import { useTransition, useTranslation } from "react-i18next";

import logo8 from "../assets/hotelchico.png"
import logo2 from "../assets/oppo.png"
import logo3 from "../assets/lahuertachico.png"
import logo4 from "../assets/Manzurchico.png"
import logo5 from "../assets/senypchico.png"
import logo6 from "../assets/gova.png"

import logo7 from "../assets/shamanachico.png"
import logo1 from "../assets/tundrachico.png"
import logo9 from "../assets/senypchico.png"
import logo10 from "../assets/gova.png"
import logo11 from "../assets/lahuertachico.png"
import logo12 from "../assets/Manzurchico.png"
import logo13 from "../assets/senypchico.png"
import logo14 from "../assets/gova.png"

import uno from '../assets/header2.png'
import v1 from '../assets/ver1.jpg'
import v2 from '../assets/ver2.jpg'


const urls = [ uno, v1, v2 ];

const urlImg =[logo1,logo2,logo3,logo4,logo5,logo6,logo7,logo8, logo1,logo2,logo3,logo4,logo5,logo6, logo7,logo8,logo1,logo2,logo3,logo4,logo5,logo6,logo1,logo2,logo3,logo4,logo5,logo6, logo7,logo8]




const Header = () => {
  const [t, i18n] = useTranslation("global")


    const [currentIndex, setCurrentIndex] = React.useState(0);

  React.useEffect(() => {
    // Cambia la imagen cada 5 segundos (5000 milisegundos)
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % urls.length);
    }, 1500);

    // Limpia el intervalo cuando el componente se desmonta
    return () => clearInterval(intervalId);
  }, []); 
  
  
 
  return(
    <div name="home" className="w-full h-full bg-lila bg-cover flex flex-col justify-between items-center ">
        <div className="grid md:grid-cols-2 max-w-[1240px] m-auto">
            <div className="flex flex-col justify-center md:items-start w-full px-4 py-8">
                <p className="text-white text-2xl">{t("header.titulo")}</p>
                <h1 className="text-white  py-11 text-5xl md:text-6xl lg:text-7xl font-bold  " style={{ lineHeight: '1.1' }} >{t("header.subtitulo")}</h1>
                
                </div>

                <div>
                <img className="'w-1/2 hidden  md:block" src={urls[currentIndex]} alt="" />
             </div>



            </div>

            <div className="ban bg-banner overflow-hidden py-3 ">
            <div   className="banner flex  justify-center overflow-hidden">
            {urlImg.map((imageUrl, index) => (
            <img
              key={index}
              src={imageUrl}
              className={`px-8 py-2 h-[70px]  `}
            />
          ))}
               </div>
            </div>
        </div>
    )
}

export default Header;