import React from "react";
import "./Cubo.css"

const Cubo = () => {

    return (
<div class="section-banner">
  <div id="star-1">
    <div class="curved-corner-star">
      <div id="curved-corner-bottomright"></div>
      <div id="curved-corner-bottomleft"></div>
    </div>
    <div class="curved-corner-star">
      <div id="curved-corner-topright"></div>
      <div id="curved-corner-topleft"></div>
    </div>
  </div>

  <div id="star-2">
    <div class="curved-corner-star">
      <div id="curved-corner-bottomright"></div>
      <div id="curved-corner-bottomleft"></div>
    </div>
    <div class="curved-corner-star">
      <div id="curved-corner-topright"></div>
      <div id="curved-corner-topleft"></div>
    </div>
  </div>

  <div id="star-3">
    <div class="curved-corner-star">
      <div id="curved-corner-bottomright"></div>
      <div id="curved-corner-bottomleft"></div>
    </div>
    <div class="curved-corner-star">
      <div id="curved-corner-topright"></div>
      <div id="curved-corner-topleft"></div>
    </div>
  </div>

  <div id="star-4">
    <div class="curved-corner-star">
      <div id="curved-corner-bottomright"></div>
      <div id="curved-corner-bottomleft"></div>
    </div>
    <div class="curved-corner-star">
      <div id="curved-corner-topright"></div>
      <div id="curved-corner-topleft"></div>
    </div>
  </div>

  <div id="star-5">
    <div class="curved-corner-star">
      <div id="curved-corner-bottomright"></div>
      <div id="curved-corner-bottomleft"></div>
    </div>
    <div class="curved-corner-star">
      <div id="curved-corner-topright"></div>
      <div id="curved-corner-topleft"></div>
    </div>
  </div>

  <div id="star-6">
    <div class="curved-corner-star">
      <div id="curved-corner-bottomright"></div>
      <div id="curved-corner-bottomleft"></div>
    </div>
    <div class="curved-corner-star">
      <div id="curved-corner-topright"></div>
      <div id="curved-corner-topleft"></div>
    </div>
  </div>

  <div id="star-7">
    <div class="curved-corner-star">
      <div id="curved-corner-bottomright"></div>
      <div id="curved-corner-bottomleft"></div>
    </div>
    <div class="curved-corner-star">
      <div id="curved-corner-topright"></div>
      <div id="curved-corner-topleft"></div>
    </div>
  </div>
</div>
    )
}

export default Cubo