import React, {useState} from 'react'
import code from '../assets/code.svg'
import ux from '../assets/ux.svg'
import ecommerce from '../assets/ecommerce.svg'
import sistema from '../assets/sistema.svg'
import staffing from '../assets/staffing.svg'
import asesoramiento from '../assets/asesoramiento.svg'
import star from '../assets/star.svg'

import {useTransition, useTranslation} from "react-i18next"

const styleOnClick={
    borderColor:"red"
}
const About = () => {
    const [t, i18n] = useTranslation("global")
    const [viwer, setViwer] = useState(true);
    const [viwer1, setViwer1] = useState(true);
    const [viwer2, setViwer2] = useState(true);
    const [viwer3, setViwer3] = useState(true);
    const [viwer4, setViwer4] = useState(true);
    const [viwer5, setViwer5] = useState(true);
    
    const onMouseLeave=()=>setViwer(true)
    const onMouseEnter=()=> setViwer(false)
    
    const onMouseLeave1 = () => setViwer1(true);
    const onMouseEnter1 = () => setViwer1(false);

    const onMouseLeave2 = () => setViwer2(true);
    const onMouseEnter2 = () => setViwer2(false);

    const onMouseLeave3 = () => setViwer3(true);
    const onMouseEnter3 = () => setViwer3(false);

    const onMouseLeave4 = () => setViwer4(true);
    const onMouseEnter4 = () => setViwer4(false);

    const onMouseLeave5 = () => setViwer5(true);
    const onMouseEnter5 = () => setViwer5(false);

    
  return (
    <div className='bg-black w-full py-11  '>

        <div className='max-w-[1240px] mx-auto pb-11'>
            <div name='servicios' className='text-center'>
                <h2 className='text-4xl sm:text-5xl font-bold '> <span className='text-white'>{t("soluciones.servicios")}</span><span className='text-rosa'>{t("soluciones.servicio")}</span></h2>
                <p className='text-2xl sm:text-3xl py-6 px-4 text-gray-500 pb-11'>{t("soluciones.subservicios")}</p>
            </div>
            {/* ls:grid-cols-1 */}
            <div className='grid md:grid-cols-3  gap-1 px-2 text-center'  >
            
                <div onMouseLeave={onMouseLeave}  onMouseEnter={onMouseEnter} style={{transition:"all ease .3s "}} className={`border h-[250px] ${viwer?'':"border-rosa"} hide-scroll-bar overflow-hidden  py-8 mb-2 ml-2 mr-2 rounded-xl shadow-xl flex flex-col items-center justify-center hover:scale-105`} >
                    <img style={{display:viwer?"":'none'}} src={ux} alt='Diseño web' className='w-20 h-20'/>
                    <p  style={{display:viwer?"":'none'}} className='text-gray-400 mt-2 '>{t("soluciones.diseño")} <br /> {t("soluciones.subdiseño")}</p>
                    <p  style={{display:viwer?'none':""}} className='text-gray-400 mt-2 px-4'>{t("soluciones.backdis")}</p>
                </div>
                
                <div onMouseLeave={onMouseLeave1}  onMouseEnter={onMouseEnter1} style={{transition:"all ease .3s "}}  className={`border h-[250px] ${viwer1?'':"border-rosa "} hide-scroll-bar overflow-hidden py-8 mb-2 ml-2 mr-2 rounded-xl shadow-xl flex flex-col items-center justify-center hover:scale-105`} >
                    <img style={{display:viwer1?"":'none'}} src={code} alt='crear pagina web' className='w-20 h-20' />
                    <p style={{display:viwer1?"":'none'}} className='text-gray-400 mt-2'>{t("soluciones.devweb")}<br />{t("soluciones.subdevweb")}</p>
                    <p  style={{display:viwer1?'none':""}} className='text-gray-400 mt-2 px-4'> {t("soluciones.backweb")}</p>
                    
                </div>

                <div onMouseLeave={onMouseLeave2}  onMouseEnter={onMouseEnter2} style={{transition:"all ease .3s "}} className={`border-2 border-amarrillo h-[250px] ${viwer2?'border':"border-amarrillo "} hide-scroll-bar overflow-hidden  py-8 mb-2 ml-2 mr-2 rounded-xl shadow-xl flex flex-col items-center justify-center hover:scale-105`} >

                    <img style={{display:viwer2?"":'none'}} src={star}  className='w-4' alt="tienda en linea" />
                    <img style={{display:viwer2?"":'none'}} src={ecommerce} alt='crear pagina web' className='w-20 h-20' />
                    <p style={{display:viwer2?"":'none'}} className='text-gray-400 mt-2'>{t("soluciones.ecommerce")}<br />{t("soluciones.subecommerce")}</p>
                    <p  style={{display:viwer2?'none':""}} className='text-gray-400 mt-2 px-4'>{t("soluciones.infocommerce")} <br /> </p> {/* se rompe sin los br     */}
                    
                </div>
                
                <div onMouseLeave={onMouseLeave3}  onMouseEnter={onMouseEnter3} style={{transition:"all ease .3s "}} className={`border h-[250px] ${viwer3?'':"border-rosa  "} hide-scroll-bar overflow-hidden py-8 mb-2 ml-2 mr-2 rounded-xl shadow-xl flex flex-col items-center justify-center hover:scale-105`} >
                    <img style={{display:viwer3?"":'none'}} src={sistema} alt='crea tu pagina web' className='w-20 h-20' />
                    <p style={{display:viwer3?"":'none'}} className='text-gray-400 mt-2'>{t("soluciones.sistemas")} <br />{t("soluciones.subsistemas")}</p>
                    <p  style={{display:viwer3?'none':""}} className='text-gray-400 mt-2 px-4'>{t("soluciones.infosistemas")}</p>
                    
                </div>

                <div onMouseLeave={onMouseLeave4}  onMouseEnter={onMouseEnter4} style={{transition:"all ease .3s "}} className={`border h-[250px] ${viwer4?'':" border-rosa  "} hide-scroll-bar overflow-hidden py-8 mb-2 ml-2 mr-2 rounded-xl shadow-xl flex flex-col items-center justify-center hover:scale-105`} >
                    <img style={{display:viwer4?"":'none'}} src={staffing} alt='pagina web' className='w-20 h-20' />
                    <p style={{display:viwer4?"":'none'}} className='text-gray-400 mt-2'>{t("soluciones.staffing")} <br /> {t("soluciones.substaffing")}</p>
                    <p  style={{display:viwer4?'none':""}} className='text-gray-400 mt-2 px-4'>{t("soluciones.infostaffing")}</p>
                    
                </div>

                <div onMouseLeave={onMouseLeave5}  onMouseEnter={onMouseEnter5} style={{transition:"all ease .3s "}} className={`border h-[250px] ${viwer5?'':"border-rosa  "} hide-scroll-bar    overflow-hidden   py-8 mb-2 ml-2 mr-2 rounded-xl shadow-xl flex flex-col items-center justify-center hover:scale-105`} >
                    <img style={{display:viwer5?"":'none'}} src={asesoramiento} alt='pagina web' className='w-20 h-20' />
                    <p style={{display:viwer5?"":'none'}} className='text-gray-400 mt-2'>{t("soluciones.consultoria")} <br />{t("soluciones.subconsultoria")}</p>
                    <p  style={{display:viwer5?'none':""}}  className='text-gray-400 mt-2 px-4'>{t("soluciones.infoconsultoria")}</p>
                    
                </div>

            </div>
        </div>

    </div>

  )
}

export default About