import React from 'react'
import { useTransition, useTranslation } from "react-i18next";

const Form = () => {
  const [t, i18n] = useTranslation("global")

    return(
      <form class="w-full max-w-lg"  action="https://formsubmit.co/matias.pasquini11@gmail.com" method="POST"  >
      <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label class="block uppercase tracking-wide text-white text-xs font-bold mb-2" for="grid-first-name">
          {t("form.nombre")}
          </label>
          <input class="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="grid-first-name" name='nombre' type="text" placeholder="Nombre"/>
          <p class="text-white text-xs italic">{t("form.fill")}</p>
        </div>
        <div class="w-full md:w-1/2 px-3">
          <label class="block uppercase tracking-wide text-white text-xs font-bold mb-2" for="grid-last-name">
          {t("form.apellido")}
          </label>
          <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" name='apellido' type="text" placeholder="Apellido"></input>
        </div>
      </div>
      <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full px-3">
          <label class="block uppercase tracking-wide text-white text-xs font-bold mb-2" for="grid-password">
            Email
          </label>
          <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="email" name='email' type="email" placeholder="Email" />
          <p class="text-gray-600 text-xs italic"></p>
        </div>
      </div>
      <div class="flex flex-wrap -mx-3 mb-6">
        <div class="w-full px-3">
          <label class="block uppercase tracking-wide text-white text-xs font-bold mb-2" for="grid-password">
          {t("form.mensaje")}
          </label>
          <textarea class=" no-resize appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none" name='message' id="message"></textarea>
          <p class="text-white text-xs italic">{t("form.contanos")}</p>
        </div>
      </div>
      <div class="md:flex md:items-center">
        <div class="md:w-1/3">
        <button className="submit inline-flex items-center justify-center p-0.5 mb-2 mt-2  overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800">
      <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-lila rounded-md group-hover:bg-opacity-0">
      {t("form.enviar")}
      </span>
    </button>
        </div>
        <div class="md:w-2/3"></div>
      </div>
      
      
      
      <input type="hidden" name="_next" value="https://randev.com.ar/"></input>  {/* modificar conla la url del home */}
       <input type="hidden" name="_captcha" value="false"></input>
      
    </form>

    )

}
export default Form;