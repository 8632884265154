import React from 'react'
import Cubo from '../cubo/Cubo'
import './Brand.css'
import { useTransition, useTranslation } from "react-i18next";

 
const Brand = () => {

    const [t, i18n] = useTranslation("global")

    return (
        <main className='pb-20 bg-black'>
            <div className='h-auto bg-black text-center pb-11 sm:pb-24  '>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#180d5b" fill-opacity="1" d="M0,96L60,117.3C120,139,240,181,360,192C480,203,600,181,720,165.3C840,149,960,139,1080,149.3C1200,160,1320,192,1380,208L1440,224L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path></svg>
            <h2 className='text-white pb-3 font-bold text-4xl sm:text-5xl'>{t("randev.que")} <span className='text-rosa'>RANDEV?</span></h2>
            <div className='grid grid-cols-1 sm:grid-cols-3 justify-center text-center pt-11 sm:pl-11 sm:px-8 px-6'>
            
            <div className='text-white  text-start col-start-1 col-end-3 sm:px-6 items-center'>
                <h3 className='text-white text-xl sm:text-2xl sm:px-4 '>{t("randev.randev")}</h3>
                <h2 className='sm:px-4  sm:pb-6 text-xl sm:text-2xl'>{t("randev.slo")} </h2>
                
                
                  
                </div>
            <div className='hidden lg:block'>
            <Cubo />

            </div>

            </div>
            
            </div>
        <div className='bg-gris h-auto py-8 px-6 flex flex-col sm:flex-row uppercase '>
            <div className='items-center text-center'>
            <h4 className=' text-xl sm:text-2xl font-bold text-white sm:pl-16 px-6 pt-1'>{t("randev.sabemos")}       </h4>  
            </div>
            <div className='items-center text-center'>
                <button className="button"  data-text="Awesome" href='#footer'>
                
                <span class="actual-text">&nbsp;{t("randev.glo")}&nbsp;</span>
                <span aria-hidden="true" class="hover-text">&nbsp;{t("randev.glo")}&nbsp;</span> 
                </button>
                </div>
        </div>
        </main>
    )
}

export default Brand