import React, {useState} from "react";
import logo from "../assets/logo-header.png"
import { Link, animateScroll as scroll, } from 'react-scroll' 
import { useTransition, useTranslation } from "react-i18next";
import banderaarg from "../assets/banderaarg.png"
import banderaeeuu from "../assets/banderaeeuu.png"
import "../App.css"

const Navbar = () => {
    const [nav, setNav] = useState(false)
    const handleClick = () => setNav(!nav)
    const [t, i18n] = useTranslation("global")
    const closeNav = () => setNav(false)


    return (
        <div className="sticky top-0 w-full h-[80px] z-10 bg-slate-900 "> 
            <div className="px-2 flex justify-between items-center w-full h-full ">
                <div className="flex items-center">
                <Link to="home" smooth={true} offset={-200} duration={500}> <img src={logo} alt="randev" className="w-[200px] cursor-pointer"/> </Link>
                    <ul  className=" hidden md:flex space-x-4">
                        <li className=" ver text-white cursor-pointer"><Link to="servicios" smooth={true} offset={-200} duration={500}>{t("navbar.servicios")}</Link></li>
                        <li className=" ver text-white cursor-pointer"><Link to="Nosotros" smooth={true} offset={-200} duration={500}>{t("navbar.nosotros")}</Link></li> 
                        <li className=" verContact text-rosa cursor-pointer"><Link to="footer" smooth={true} offset={-200} duration={500}>{t("navbar.contactanos")}</Link></li> 
                        {/*<li className="verContact text-rosa cursor-pointer"> <a href="https://wa.me/+5493885085095?text=Hola%20quiero%20obtener%20información" target="_blank" rel="noopener noreferrer">{t("navbar.contactanos")}</a></li> */}
                        
                        {/*<li className="text-white cursor-pointer"><Link to="proyectos" smooth={true} offset={-200} duration={500}>{t("navbar.proyectos")}</Link></li> */}
                        
                    </ul>
                </div>
                <div className="hidden md:flex pr-4">
                    <button className="w-10 px-2 cursor-pointer"  onClick={() => i18n.changeLanguage("es")}><img src={banderaarg}></img></button>
                    <button className="w-10 px-2 cursor-pointer"  onClick={() => i18n.changeLanguage("en")}><img src={banderaeeuu}></img></button>
                {/*<button className="relative inline-flex items-center justify-center p-0.5 mb-2 mt-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800">
                <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                {t("navbar.contactanos")}
                </span>
    </button>*/}
                </div>
                <div className="md:hidden " onClick={handleClick}>
                    {!nav ?  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
</svg> : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
</svg>
}

                </div>
            </div>
        <ul className={!nav ? "hidden" : "absolute bg-slate-900 w-full px-8"}>
        <li className="py-4 pl-1 text-white w-full"><Link to="servicios" smooth={true} offset={-200} duration={500} onClick={closeNav}>{t("navbar.servicios")}</Link></li>
        <li className="pb-4 pl-1 text-white w-full"><Link to="Nosotros" smooth={true} offset={-200} duration={500} onClick={closeNav}>{t("navbar.nosotros")}</Link></li> 
        
        {/*<li className="border-b-2  text-white w-full"><Link to="proyectos" smooth={true} offset={-200} duration={500} onClick={closeNav}>{t("navbar.proyectos")}</Link></li> */}

        <div className="pb-4">
        <li className="pb-4 pl-1 text-rosa w-full"><Link to="footer" smooth={true} offset={-200} duration={500} onClick={closeNav}>{t("navbar.contactanos")}</Link></li> 

    <button className="w-10 px-2"  onClick={() => i18n.changeLanguage("es")}><img src={banderaarg}></img></button>
    <button className="w-10 px-2"  onClick={() => i18n.changeLanguage("en")} ><img src={banderaeeuu}></img></button>
        </div>
        </ul>


        </div>
    )
}

<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0,0,256,256"
style="fill:#FFFFFF;">
<g fill="#ffffff" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(10.66667,10.66667)"><path d="M2,5v2h20v-2zM2,11v2h20v-2zM2,17v2h20v-2z"></path></g></g>
</svg>

export default Navbar

